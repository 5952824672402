/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import './styled.css'
class Talentpool extends React.Component {
  render() {
    return (
      <div className="talentpool spb-10">
      	<Container>
        	<Row>
	            <Col lg="3">
	              <h3>Our talent pool</h3>
	            </Col>
	            <Col lg="9">
	              <Row>
	                <Col sm="4">
	                	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 589.36 445.07"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,438.72c0-3.66,3.42-6.35,7.32-6.35h202.4a117.43,117.43,0,0,1-33.21-81.78c0-64.7,53.23-117.92,118.17-117.92s118.16,53.22,118.16,117.92a117.42,117.42,0,0,1-33.2,81.78H582c3.91,0,7.33,2.69,7.33,6.35s-3.42,6.35-7.33,6.35H7.32C3.42,445.07,0,442.38,0,438.72Zm29.3-93.51a6.63,6.63,0,0,1,6.34-6.34h85.21a6.46,6.46,0,0,1,6.35,6.34,6.29,6.29,0,0,1-6.35,6.35H35.64A6.82,6.82,0,0,1,29.3,345.21Zm77.63-172.85a6.07,6.07,0,0,1,.25-9c1.95-2.2,6.1-2.44,8.79,0l60.3,60.3a6.4,6.4,0,0,1-9,9Zm181.16-13.18V61.77l1-42L263.67,45.9l-39.3,40a5.92,5.92,0,0,1-4.64,2.19,6.41,6.41,0,0,1-6.6-6.59c0-2.44,1.23-4.39,3.42-6.59L289.79,2.2a6.52,6.52,0,0,1,9.77,0L372.8,75c2.2,2.2,3.42,4.15,3.42,6.59a6.41,6.41,0,0,1-6.59,6.59A5.91,5.91,0,0,1,365,85.94l-39.06-39.8L300.29,19.78l1,42v97.41a6.59,6.59,0,1,1-13.18,0Zm125,64.45,60.3-60.3c2.68-2.44,6.83-2.2,8.79,0a6.07,6.07,0,0,1,.24,9l-60.3,60.31a6.4,6.4,0,0,1-9-9Zm49.07,121.58a6.63,6.63,0,0,1,6.35-6.34h85.2a6.31,6.31,0,0,1,6.35,6.34,6.46,6.46,0,0,1-6.35,6.35h-85.2A6.82,6.82,0,0,1,462.16,345.21Z"/></g></g></svg>
	                  	<h5>Product Owners</h5>
	                  	<p>Our expert product owners will drive the delivery of your digital team as well as assist in building the roadmap for any products or services you are developing.</p>
	                </Col>
	                <Col sm="4">
	                	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 674.07 464.6"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M299.07,436.28l-89.6-65.43H200c-68.11,0-119.14-21-150.15-65.92C20.51,304.2,0,288.82,0,262.45c0-23.92,18.07-38.33,42.72-39.06C73,143.55,129.88,101.81,205.57,101.81c72.26,0,125.73,41,194.58,79.59,27.1,16.84,40,21.48,54.44,21.48,11.23,0,23.44-7.08,44.43-28.32L365.48,98.88c-29.54-16.85-63-37.6-63-55.42C302.49,18.31,346.44,0,379.64,0c41.75,0,82.52,23.19,115.48,65.92l56.88,83c59.08-6.84,122.07,49.8,122.07,127.44,0,51.27-26.61,65.43-85.2,65.43-34.43,0-71.78-14.16-96.68-38.82-20,20.75-34.91,33.69-48.59,43.7-13.42-1.71-21-2.69-33.93-2.69-17.82,0-34.91,1.47-49.56,4.89L344,309.57Q300.05,203,202.88,202.88c-18.31,0-36.38,2.93-36.38,14.89,0,6.6,4.4,9.77,12.21,9.77h26.37c51.51,0,92.28,30.52,118.16,88.38l23.44,53.22c17.58-4.64,33-7.57,64.94-7.57,63.48,0,112.55,26.86,112.55,64,0,16.11-18.8,30.51-52.73,30.51-22.47,0-31.74-7.32-59.57-7.32-26.62,0-43.95,7.32-58.84,7.32C335.69,456.05,316.41,451.42,299.07,436.28ZM105.47,416.5c0-17.57,11.47-29.54,36.38-29.54,14.64,0,39.55,3.42,47.36,3.42a87.2,87.2,0,0,0,12.45-.73l71.78,54.2C249,458.5,221.19,464.6,179.2,464.6,131.59,464.6,105.47,445.31,105.47,416.5ZM595.21,244.14a15.58,15.58,0,0,0-15.86-15.38c-8.31,0-15.63,7.32-15.63,16.36,0,8.54,7.08,16.11,15.63,16.11C587.65,261.23,595.21,253.17,595.21,244.14Z"/></g></g></svg>
	                  		<h5>Agile Project Managers</h5>
	                  		<p>We provide project managers that are well versed in all of the relevant agile delivery frameworks.</p>
	                </Col>
	                <Col sm="4">
	                	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 633 510"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M2.38,260.61a7.25,7.25,0,0,1,0-10.74L186.46,83.37c3.18-2.69,7.33-2.44,9.77.49a6.66,6.66,0,0,1-.73,9.52L16.78,255.24,195.5,417.11a6.66,6.66,0,0,1,.73,9.52c-2.44,2.93-6.59,3.17-9.77.49ZM237.73,501.09,382.26,5.24c1.22-4.15,4.89-6.1,8.55-4.88S396.18,5,395.2,8.9L250.67,504.75a6.51,6.51,0,0,1-8.54,4.89A7,7,0,0,1,237.73,501.09Zm199-74.95a6.88,6.88,0,0,1,.73-9.52L616.15,254.75,437.44,92.64a6.66,6.66,0,1,1,9-9.76L630.8,249.14a8,8,0,0,1,0,11L446.47,426.38A6.9,6.9,0,0,1,436.71,426.14Z"/></g></g></svg>
	                  		<h5>Full Stack Developers</h5>
	                 		<p>Our reliable full stack developers deliver robust and well-built software. Our team is proficient in a multitude of development languages.</p>
	                </Col>
	              </Row>
	              <Row>
	                <Col sm="4">
	                	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 632.08 403.81"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,150.39V31C0,9.28,11,0,31,0H263.92c20,0,31,9.28,31,31V150.39c0,21.73-11,31-31,31H31C11,181.4,0,172.12,0,150.39ZM58.59,372.8V282.71C58.59,261,69.34,252,89.36,252H293.21c20,0,31,9,31,30.76V372.8c0,21.73-11,31-31,31H89.36C69.34,403.81,58.59,394.53,58.59,372.8Zm340.58-31.74V66.16c0-21.73,10.74-31,30.76-31H601.32c20,0,30.76,9.27,30.76,31v274.9c0,22-10.74,31-30.76,31H429.93C409.91,372.07,399.17,363,399.17,341.06Z"/></g></g></svg>
	                  	<h5>UX/UI Designers</h5>
	                  	<p>Our user experience (UX) and user interface (UI) designers are skilled in creating beautiful but hard working technically sound interfaces.</p>
	                </Col>
	                <Col sm="4">
	                	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 483.52 451.42"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M51.76,230.71c-9-36.13-10-41.5-49.56-49.56-1.71-.24-2.2-1-2.2-2.19s.49-2,2.2-2.2C41.75,168.7,42.72,163.33,51.76,127c.24-1.22,1-2,2-2s1.71.73,1.95,2c9,36.38,10.26,41.75,49.56,49.81,1.71.24,2.45,1,2.45,2.2s-.74,1.95-2.45,2.19c-39.3,8.06-40.52,13.43-49.56,49.56-.24,1.47-1,2-1.95,2S52,232.18,51.76,230.71ZM159.67,422.85c-10.26-61.52-9-59.81-72.27-72.26a3.19,3.19,0,0,1,0-6.35c63.23-12.45,62.26-10.74,72.27-72.27a3.16,3.16,0,0,1,3.17-2.93A3,3,0,0,1,166,272c10.25,61.53,9.27,59.57,72.51,72.27a3,3,0,0,1,2.68,3.17,3,3,0,0,1-2.93,3.18c-63,12.45-62,10.49-72.26,72.26a3,3,0,0,1-3.18,2.93A3.16,3.16,0,0,1,159.67,422.85ZM169.43,95.7c-8.3-33-9-37.84-44.92-44.92-1.46-.24-2.2-1.22-2.2-2,0-1,.74-1.71,2.2-1.95C160.4,39.79,161.13,34.67,169.43,2c.25-1.22.74-1.95,1.71-1.95s1.22.73,1.47,2c8.3,32.72,9.27,37.84,45.16,44.93,1.47.24,2.2,1,2.2,1.95s-.73,1.71-2.2,2c-35.89,7.08-36.86,12-45.16,44.92-.25,1.22-.49,2-1.47,2S169.68,96.92,169.43,95.7Zm67.63,127.44a13.55,13.55,0,0,1,0-19.28,13.78,13.78,0,0,1,19.29,0L479.49,427.73c5.62,6.11,5.13,13.68,0,19.29-5.37,5.62-13.43,6.1-19.28,0Zm85.69,59.09-71-70.8c-2.93-3.18-5.37-3.67-7.81-1.22-2.69,2.68-2.2,5.12,1,8l70.56,71.29Zm23.44-113.77c-9-36.38-9.76-41.75-49.56-49.81-1.46-.24-2.2-1-2.2-2.19s.74-2,2.2-2.2c39.8-8.06,40.53-13.43,49.56-49.56.49-1.47,1-2,1.95-2,1.23,0,1.71.49,2.2,2,9,36.13,10,41.5,49.56,49.56,1.47.24,2.2,1,2.2,2.2s-.73,2-2.2,2.19c-39.55,8.06-40.52,13.43-49.56,49.81-.49,1.22-1,1.95-2.2,1.95C347.17,170.41,346.68,169.68,346.19,168.46Z"/></g></g></svg>
	                  		<h5>UX Researchers</h5>
	                  		<p>Our researchers are skilled in various global best practice methodologies from Design Thinking to Google Design Sprints and User Testing.</p>
	                </Col>
	                <Col sm="4">
	                	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 441.65 375.73"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,248.29a6.23,6.23,0,0,1,6.59-6.35H435.06a6.47,6.47,0,1,1,0,12.94H6.59A6.28,6.28,0,0,1,0,248.29ZM0,369.38a6.28,6.28,0,0,1,6.59-6.59H435.06a6.28,6.28,0,0,1,6.59,6.59c0,3.91-2.69,6.35-6.59,6.35H6.59C2.69,375.73,0,373.29,0,369.38ZM3.17,90.58C3.17,42,41.26,0,89.84,0,95,0,98.39,2.93,98.39,7.32c0,4.15-2.93,7.08-7.57,7.08-35.89,0-68.6,28.81-73.49,72.76,7.08-13.92,21.24-23.44,38.82-23.44,27.59,0,46.14,19.53,46.14,44.43,0,25.88-21.24,47.61-46.63,47.61C24.17,155.76,3.17,130.13,3.17,90.58Zm125.74,0C128.91,42,166.75,0,215.58,0c4.88,0,8.54,2.93,8.54,7.32,0,4.15-2.93,7.08-7.57,7.08-35.89,0-68.11,28.57-73.24,72.51a42.78,42.78,0,0,1,38.33-23.19c27.83,0,46.14,19.53,46.14,44.43,0,25.88-21,47.61-46.14,47.61C150.15,155.76,128.91,130.13,128.91,90.58Zm137.2-84A6.29,6.29,0,0,1,272.71,0H435.06a6.28,6.28,0,0,1,6.59,6.59,6.28,6.28,0,0,1-6.59,6.59H272.71A6.28,6.28,0,0,1,266.11,6.59Zm0,120.85c0-3.9,2.69-6.35,6.6-6.35H435.06c3.9,0,6.59,2.45,6.59,6.35a6.28,6.28,0,0,1-6.59,6.59H272.71A6.28,6.28,0,0,1,266.11,127.44Z"/></g></g></svg>
	                  	<h5>UX Writers</h5>
	                  	<p>Our writers understand how to clearly convey complex products and services for the targeted audience for any device and channel.</p>
	                </Col>
	              </Row>
	              <Row>
	             	<Col sm="4">
	             		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 631.59 485.6"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,385.25a6.3,6.3,0,0,1,6.35-6.34h167c13.92-34.43,39.31-71.54,74-102.05C164.31,231,127.93,166.75,127.93,104c0-58.59,41.5-104,94.48-104,83.25,0,137.94,91.31,140.14,217.53a236.37,236.37,0,0,1,51.51-5.62c25.15,0,38.58,10,38.58,28.81,0,18.31-14.16,40.53-14.16,58.6,0,5.12,2.68,7.08,6.59,7.08,26.37,0,81.54-84.72,115.72-84.72,40.28,0,8.79,75.93,39.55,75.93,9.52,0,17.58-6.59,22.22-6.59,3.9,0,6.1,3.17,6.1,6.59a7.54,7.54,0,0,1-4.15,6.59c-9,5.13-16.84,7.08-24.9,7.08-46.88,0-16.6-75.2-40.77-75.2-23.44,0-76.42,84.48-116.21,84.48-9.28,0-19.05-4.64-19.05-18.31,0-20,14.16-43.95,14.16-61,0-10.5-8.54-14.89-24.65-14.89a242.73,242.73,0,0,0-50.54,5.37c-.49,55.66-9.77,106.2-25.88,147.22H625.24a6.3,6.3,0,0,1,6.35,6.34,6.46,6.46,0,0,1-6.35,6.35H331.3c-26.12,57.86-66.65,94-115.72,94-33.21,0-53.71-22-53.71-53.47,0-12.21,2.44-26.12,7.08-40.53H6.35A6.46,6.46,0,0,1,0,385.25Zm2.2-75.92,34.91-34.67L2.2,240a6.06,6.06,0,0,1,0-9,6.06,6.06,0,0,1,9,0L45.9,265.62,80.81,231a6.06,6.06,0,0,1,9,0,6.46,6.46,0,0,1,0,9L54.93,274.66l34.91,34.67a6.39,6.39,0,1,1-9,9L45.9,283.45,11.23,318.36a6.48,6.48,0,0,1-9,0A6.06,6.06,0,0,1,2.2,309.33Zm257.32-42.48a260.13,260.13,0,0,1,88.87-45.66c-1.22-114.26-49.81-206.79-126-206.79-45.16,0-80.08,39.31-80.08,89.6C142.33,175.78,193.6,229.25,259.52,266.85Zm-42,204.59c40.77,0,74.71-31,97.9-79.84H183.11C178.47,405.76,176,419,176,430.91,176,453.61,188.72,471.44,217.53,471.44Zm42.48-188c-33.69,28.32-58.35,63-72,95.46H321c16.61-40,26.13-89.85,27.35-143.31a237.58,237.58,0,0,0-76.17,38.33c8,4.15,16.35,8.3,24.9,12.2,3.42,1.47,4.64,3.66,4.64,6.35a6.1,6.1,0,0,1-6.35,6.1,9.3,9.3,0,0,1-4.15-1A338.54,338.54,0,0,1,260,283.45Z"/></g></g></svg>
	                  		<h5>QA Testers</h5>
	                  		<p>Our QAs have the most impact when brought in early. We continually improve our automated test scripts for each business use case.</p>
	                </Col>
	                <Col sm="4">
	                	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1528.19 924.2"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,897.73c0-7.93,5.29-13.89,15.88-13.89,7.28,0,23.15,1.33,38.36,1.33,78.73,0,107.18-38.38,128.35-138.27l88.64-418.77H155.47c-10.59,0-17.87-5.95-17.87-16.53s7.94-16.54,18.53-16.54H278.51L305,168C329.45,52.92,371.13,1.33,467.72,1.33c41.67,0,56.89,6,56.89,21.17,0,8.6-5.29,13.89-15.88,13.89-7.27,0-23.15-1.33-38.36-1.33-78.73,0-107.18,38.37-128.35,138.27l-25.8,121.73H451.85c9.91,0,15.87,5.95,15.87,15.88,0,10.58-6.62,17.19-16.54,17.19H309L219.64,752.19C195.16,868,153.48,918.9,56.89,918.9,15.22,918.9,0,913,0,897.73ZM768.72,913C654.94,785.27,603.34,642.37,603.34,462.43c0-179.28,57.56-339.38,165.38-451.85C776,2.65,779.31,0,786.59,0c6.62,0,12.57,4.63,12.57,12.57a16.27,16.27,0,0,1-4,10.59C694.63,131,637.74,297,637.74,463.09c0,189.21,66.16,328.8,156.79,436.63,3.3,4,4.63,7.28,4.63,11.91,0,7.94-5.95,12.57-12.57,12.57C779.31,924.2,774.68,920.23,768.72,913Zm86-170.68c0-2.65.66-7.28,5.29-13.23l186.56-274.55L863.33,197.81c-4-5.95-6.61-10.59-6.61-16.54,0-9.27,7.27-16.54,17.85-16.54,7.94,0,11.92,4,18.53,13.89L1067.75,428h2L1245,178.62c6.62-10.58,11.26-13.89,18.53-13.89,8.6,0,17.86,6.62,17.86,15.88,0,5.95-2,9.26-6.61,15.87l-184.58,258L1277.47,729c3.3,5.29,4.62,9.92,4.62,12.57,0,9.26-7.27,17.2-17.85,17.2-7.28,0-11.92-2-16.55-9.26L1069.08,484.92h-1.33l-177.3,262c-6.61,9.26-11.24,11.91-17.86,11.91C862.67,758.81,854.73,752.19,854.73,742.27Zm477,169.36c0-4.63,2-7.94,5.29-11.91,90.63-107.83,156.79-247.42,156.79-436.63,0-166-57.55-332.1-157.45-439.93-2.64-3.31-4.63-6.62-4.63-10.59,0-7.94,6-12.57,12.57-12.57,7.28,0,11.25,2.65,18.53,10.58C1470,123.05,1528.19,283.15,1528.19,462.43c0,179.94-52.26,322.84-165.38,450.52-6.62,7.28-11.25,11.25-18.53,11.25C1337.66,924.2,1331.71,919.57,1331.71,911.63Z"/></g></g></svg>
	                	<h5>Data Scientists</h5>
	                  	<p>Our data scientists know how to collaborate well with business stakeholders using python to create models that are beneficial for business.</p>
	                </Col>
	                <Col sm="4">
	                	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 584.72 220.95"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M37.35,116.21H10.25C2.93,116.21,0,112.79,0,106.69v-3.17C0,97.41,2.93,94,10.25,94H38.33c8.06-53,54-94,109.38-94A110.75,110.75,0,0,1,255.62,86.43c11-5.86,25.14-8.06,36.62-8.06s25.63,2.2,36.62,8.06a110.58,110.58,0,0,1,217.28,7.81l.49-.25h27.83c7.08,0,10.26,3.42,10.26,9.53v3.17c0,6.1-3.18,9.52-10.26,9.52h-27.1C544.19,174.32,495.85,221,436.77,221A110.39,110.39,0,0,1,326.42,110.6v-5.13a75.3,75.3,0,0,0-34.18-8.55c-10.5,0-24.17,2.69-34.18,8.55a36.72,36.72,0,0,1,.24,5.13C258.3,171.14,209,221,147.71,221,88.62,221,40.28,174.32,37.35,116.21Zm207.77-5.61c0-54-44-97.66-97.41-97.66a97.54,97.54,0,1,0,97.41,97.66Zm289.3,0A97.53,97.53,0,1,0,436.77,208,97.63,97.63,0,0,0,534.42,110.6Z"/></g></g></svg>
	                  	<h5>Specialist Development</h5>
	                  	<p>We have specialists with deep expertise in the fields of VR, Ai, machine learning and blockchain development.</p>
	                </Col>
	              </Row>
	            </Col>
          </Row>
      	</Container>
      </div>
    )
  }
}

export default Talentpool
