/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const NewCapTopimage = () => {
  const NewimageTopImages = useStaticQuery(graphql`
      query NewimageTopImages {
        topbanner: file(absolutePath: {regex: "/assets/images/services/newcapabilities/banner-img.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 2560) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="scontent-wrap introduction">
        <Row className="sp-5">
          <div className="pb-5 col-12">
            <Link className="reversearrow" to="/services">
              View all services
              <svg
                className="styled__Arrow-d87459-0 bQLhSh"
                width="14"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="#030303" fill="none" fillRule="evenodd">
                  <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                  <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                </g>
              </svg>
            </Link>
          </div>
          <Col lg="7">
            <h1>New capabilities</h1>
            <p>We build long-lasting capability inside companies by deploying internal teams, hiring, onboarding and coaching multi-disciplinary teams.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <figure>
              <Image className="img-fluid" sizes={NewimageTopImages.topbanner.childImageSharp.sizes} alt="We build long-lasting capability inside companies by deploying internal teams, hiring, onboarding and coaching multi-disciplinary teams."/>
            </figure>
          </Col>
        </Row>
        <Row className="spb-10 border-bottom">
          <Col className="testimonial">
            <h2>The secret to their success is their ability to assemble talented teams of people that really care. Urbian’s internal ‘can-do’ team culture rubs off on our people everyday. They are critical to our ongoing success and are far more than just an in-sourced function.
            </h2>
            <span>
              Stephen Andrew, Head of IT Liberty Insurance Direct
            </span>
          </Col>
        </Row>
      </Container>
    )
  }
export default NewCapTopimage
