/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const NewCapCasestudies = () => {
  const NewCapCasestudiesImages = useStaticQuery(graphql`
      query NewCapCasestudiesImages {
        liberty: file(absolutePath: {regex: "/assets/images/services/newcapabilities/LibertyThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        babylonstoren: file(absolutePath: {regex: "/assets/images/services/newcapabilities/BabylonstorenThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="spb-10 Casestudies-s">
        <Container>
          <Row>
            <Col sm="12" lg="5" xl="4" className="titlef sp-5">
              <h2>We have built internal capability for these companies</h2>
            </Col>
           </Row>
           <Row>
              <Col sm="6">
                <div>
                  <div className="project-img">
                    <Image className="img-fluid" sizes={NewCapCasestudiesImages.liberty.childImageSharp.sizes} alt="Building Liberty Direct Insurance’s capability for 4 years and counting…"/>
                  </div>
                  <small class="clientname liberty">Liberty direct insurance</small>
                  <h6 className="h3">Building Liberty Direct Insurance’s capability for 4 years and counting…</h6>
                  <p>We created one solution delivery team made up of Urbian Product Managers, UX Designers, Developers and QA’s embedded alongside internal Liberty QA’s and Underwriters in an ongoing digital transformation of Liberty Direct Insurance.</p>
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <div className="project-img">
                    <Image className="img-fluid" sizes={NewCapCasestudiesImages.babylonstoren.childImageSharp.sizes} alt="A 5 year relationship building world class end-to-end e-commerce while building the internal team to match."/>
                  </div>
                  <small class="clientname babylonstoren">Babylonstoren</small>
                  <h6 className="h3">A 5 year relationship building world class end-to-end e-commerce while building the internal team to match.</h6>
                  <p>A relationship that started as an e-commerce project grew into a 5 year relationship with us deploying a multi-disciplinary internal team and ended with us hiring their 1st team of developers which is now over 18 people and growing.</p>
                </div>
              </Col>
            </Row>
        </Container>
      </div>
    )
  }


export default NewCapCasestudies
