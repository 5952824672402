/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const newcapabilitiesArticles = () => {
  const newcapabilitiesArticleImages = useStaticQuery(graphql`
      query newcapabilitiesArticleImages {
        team: file(absolutePath: {regex: "/assets/images/TheresNoIInUX.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        lazy: file(absolutePath: {regex: "/assets/images/WeLoveLazyCoders.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="spb-10 Articles_s border-bottom">
        <Row>
          <Col sm="12" lg="5" xl="4" className="titlef sp-5">
            <h2>Read more on our views of healthy teams</h2>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <a href="/read/theres-no-i-in-ux-its-a-team-sport">
              <div className="Articles-img">
                <Image className="img-fluid" sizes={newcapabilitiesArticleImages.team.childImageSharp.sizes} alt="There’s no ‘i’ in UX, It’s a Team Sport"/>
              </div>
              <div class="card-body">
                <h6 className="h3">There’s no ‘i’ in UX, It’s a Team Sport</h6>
                <p>I thought I would share the slides from a UX presentation I did based on the Kano model, how it applies to UX principles and how the ownership of UX in any company can’t be owned by one person called a UX designer.</p>
              </div>
            </a>
          </Col>
          <Col sm="6">
            <a href="/read/why-we-love-lazy-coders">
              <div className="Articles-img">
                <Image className="img-fluid" sizes={newcapabilitiesArticleImages.lazy.childImageSharp.sizes} alt="Why we love lazy coders"/>
              </div>
              <div class="card-body">
                <h6 className="h3">Why we love lazy coders</h6>
                <p>Being lazy doesn’t mean that you’re stupid. Most of the time it means you’re actually rather smart.</p>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    )
  }


export default newcapabilitiesArticles
